import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import FormInput from "../../common/Form-Input/FormInput";
import { resetPassword } from "../../../actions/authActions";
import { validateResetPassword } from "../../../validations/auth";
import "./styles.scss";

const ResetPassword = (props) => {
  // const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("tizr_token"))
      props.history.push("/admin/dashboard");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = validateResetPassword(
      // oldPassword,
      newPassword,
      confirmPassword
    );
    if (result) return toast.error(result);

    let data = {
      id: props.match.params.id,
      token: props.match.params.token.substring(
        0,
        props.match.params.token.length - 1
      ),
      // oldPassword,
      newPassword,
    };

    props.resetPassword(data, props.history);

    // setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <React.Fragment>
      <div className="admin-reset-password">
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-md-5 mx-auto">
              <div className="card">
                <div className="card-body">
                  <h5>Reset Password</h5>
                  <form onSubmit={handleSubmit}>
                    {/* <FormInput
                      icon="fa fa-envelope icon"
                      type="password"
                      name="oldPassword"
                      placeholder="Old Password"
                      value={oldPassword}
                      handleChange={(e) => setOldPassword(e.target.value)}
                    /> */}

                    <FormInput
                      icon="fa fa-envelope icon"
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      value={newPassword}
                      handleChange={(e) => setNewPassword(e.target.value)}
                    />

                    <FormInput
                      icon="fa fa-envelope icon"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      handleChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <div className="form-group mb-0 mt-4">
                      <input
                        type="submit"
                        value="Reset"
                        className="btn btn-dark btn-block reset-password-btn shadow-none"
                      />
                    </div>

                    <div className="form-group mb-0 mt-3">
                      <Link to="/admin/login">Back to Login</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { resetPassword })(
  withRouter(ResetPassword)
);
