import React from "react";

import Statistic from "./Statistic";

const Statistics = (props) => {
  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-md-4 pl-0">
            <Statistic
              value={props.totalUsers}
              name="Registered Users"
              icon="user-plus"
              size={35}
              bgColor="#258292"
            />
          </div>

          <div className="col-md-4 pl-0">
            <Statistic
              value={props.totalEarnings}
              name="Earnings by Users"
              icon="dollar"
              size={35}
              bgColor="#123148"
            />
          </div>

          <div className="col-md-4 pl-0">
            <Statistic
              value={props.withdrawRequests}
              name="Withdrawl Requests"
              icon="sign-out"
              size={35}
              bgColor="#000"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Statistics;
