import React from "react";
import { Modal } from "react-bootstrap";

import Button from "../Button/Button";
import FormInput from "../Form-Input/FormInput";
import Radio from "../Radio/Radio";
import "./styles.scss";

const EditUser = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.showEditUserModal}
        onHide={props.handleEditUserModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update User Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleEditUserSubmit}>
            <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="name"
              placeholder="Name"
              value={props.name}
              handleChange={props.handleChange}
            />

            <FormInput
              icon="fa fa-user icon"
              size="15px"
              type="text"
              name="username"
              placeholder="Username"
              value={props.username}
              handleChange={props.handleChange}
            />

            <FormInput
              icon="fa fa-envelope icon"
              size="13px"
              type="email"
              name="email"
              placeholder="Email"
              className="form-control shadow-none"
              value={props.email}
              handleChange={props.handleChange}
            />

            <FormInput
              icon="fa fa-phone icon"
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              className="form-control shadow-none"
              value={props.phoneNumber}
              handleChange={props.handleChange}
            />

            <div className="form-group">
              <label>Status</label>
              <Radio
                name="status"
                text="Active"
                checked={props.activeBtn}
                handleChange={props.handleStatusChange}
              />
              <Radio
                name="status"
                text="Suspended"
                checked={props.suspendBtn}
                handleChange={props.handleStatusChange}
              />
            </div>

            <Button
              type="submit"
              value="Update User"
              className="btn btn-primary btn-block add-user-btn shadow-none"
            />
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EditUser;
