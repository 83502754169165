import isEmpty from "is-empty";

export const validateAddUser = (name, email, phoneNumber, username) => {
  if (isEmpty(name)) return "Please enter name";

  if (username && isEmpty(username)) return "Please enter username";

  if (isEmpty(email)) return "Please enter email";

  if (isEmpty(phoneNumber)) return "Please enter phone number";
  if (phoneNumber.length !== 10) return "Incorrect phone number";
};
