import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_PROFILE,
  GET_USERS,
  GET_USER,
  GET_EARNINGS,
  GET_CMS,
  GET_FAQ,
  ALL_NOTIFICATIONS,
  ALL_WITHDRAW_REQUESTS,
  GET_WITHDRAW,
  GET_CUSTOM_NOTIFICATION,
  ALL_ADMIN_NOTIFICATIONS,
} from "./types";
import { api_url } from "../utils/api";

export const getProfile = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/profile/get`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_PROFILE, payload: res.data.data.profile });
      }
    })
    .catch((err) => console.log(err));
};

export const submitProfile = (profile, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/profile/submit`, profile, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const changePassword = (oldPass, newPass, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/change/password`,
      { old_password: oldPass, new_password: newPass },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) {
        toast.error(res.data.error);
      }

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const getAllUsers = (page, limit, userType, socialType, token) => (
  dispatch
) => {
  console.log("userType, socialType---++", userType, socialType);
  axios
    .get(
      api_url +
        `/api/admin/users/all?page=${page}&limit=${limit}&userType=${userType}&socialType=${socialType}`,
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        console.log("users oftizr---", res.data.data.users)
       dispatch({ type: GET_USERS, payload: res.data.data.users || []});
      }
    })
    .catch((err) => console.log(err));
};

export const getUser = (id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/user/get?id=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_USER, payload: res.data.data.user });
      }
    })
    .catch((err) => console.log(err));
};

export const updateUser = (user, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/users/update`, user, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const searchUsers = (query, token) => (dispatch) => {
  console.log(query);
  axios
    .get(api_url + `/api/admin/users/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_USERS, payload: res.data.data.users });
      }
    })
    .catch((err) => console.log(err));
};

export const createUser = (user, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/users/add`, user, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const suspendUserAccount = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/users/suspend/account`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const activateUserAccount = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/users/activate/account`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};
export const deleteUserAccount = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/users/delete/account`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const getEarnings = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/earning/all`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_EARNINGS, payload: res.data.data.earnings });
      }
    })
    .catch((err) => console.log(err));
};

export const updateEarnings = (earningData, loginData, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/earning/update`,
      { earningData, loginData },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const getCMS = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/cms/get`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_CMS, payload: res.data.data.cms });
      }
    })
    .catch((err) => console.log(err));
};

export const addFAQ = (faq, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/faq/add`,
      { faq },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const getFAQ = (id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/cms/faq/get?id=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_FAQ, payload: res.data.data.faq });
      }
    })
    .catch((err) => console.log(err));
};


export const updateFAQ = (faq, token) => (dispatch) => {
  axios
    .post(api_url + `/api/admin/cms/faq/update`, faq, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
       
        toast.success(res.data.msg);
        dispatch({ type: GET_CMS, payload: res.data.data.cms });
      }
    })
    .catch((err) => console.log(err));
};

export const searchFAQ = (query, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/cms/faq/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        console.log(res.data);
        dispatch({ type: GET_CMS, payload: res.data.data.cms });
      }
    })
    .catch((err) => console.log(err));
};

export const markFAQInactive = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/faq/mark/inactive`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const markFAQActive = (id, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/faq/mark/active`,
      { id },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const addAbout = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/about/update`,
      { about: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};
export const addPolicy = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/policy/update`,
      { policy: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const addTerms = (text, token) => (dispatch) => {
  axios
    .post(
      api_url + `/api/admin/cms/terms/update`,
      { terms: text },
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const getAllNotifications = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/notifications/list`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: ALL_NOTIFICATIONS,
          payload: res.data.data.notifications,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const getAllAdminNotifications = (page, limit, token) => (dispatch) => {
  console.log("calls getall noto")
  axios
    .get(api_url + `/api/admin/notifications/adminNotifications?page=${page}&limit=${limit}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        console.log("res.data.responseData.adminNotifications", res.data)
        dispatch({
          type: ALL_ADMIN_NOTIFICATIONS,
          payload: res.data.data.adminNotifications || [],
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getAdminReadedNotifications = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/notifications/list/read`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: ALL_NOTIFICATIONS,
          payload: res.data.responseData.notifications,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const markNotificationAsRead = (id, deleteType, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/notification/mark/read?id=${id}`, {
      headers: { "x-access-token": token, "delete-type": deleteType },
    })
    .then((res) => {
      if (res.data.error === 200) {
        toast.error(res.data.error);
      }

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

//Withdraw ---------------------------------------------------
export const getAllWithdrawRequest = (page, limit, requestType, token) => (dispatch) => {
  axios
  .get(
    api_url +
      `/api/admin/withdraw/allWithdrawlRequest?page=${page}&limit=${limit}&requestType=${requestType}`,
    {
      headers: { "x-access-token": token },
    }
  )
    .then((res) => {
      if (res.data.success) {
        console.log("res.data.withdrawRequests---", res.data.data.withdrawRequests)
        dispatch({
          type: ALL_WITHDRAW_REQUESTS,
          payload: res.data.data.withdrawRequests,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const getWithdrawDetail = (id, token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/withdraw/get?id=${id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_WITHDRAW, payload: res.data.data.withdrawDetail });
      }
    })
    .catch((err) => console.log(err));
};


export const updatePaymentStatus = (withdrawId, id, token) => (dispatch) => {
  const payload = { withdrawId, userId: id};
  axios
    .post(api_url + `/api/admin/withdraw/updateStatus`, payload, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);
     console.log("updatedData", res.data.data)
     if (res.data.success) {
      dispatch({ type: GET_WITHDRAW, payload: res.data.data.updatedData });
    }
    })
    .catch((err) => console.log(err));
};

export const searchWithdrawUsers = (query, token) => (dispatch) => {
  console.log(query);
  axios
    .get(api_url + `/api/admin/users/search?query=${query}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        dispatch({ type: GET_USERS, payload: res.data.data.users });
      }
    })
    .catch((err) => console.log(err));
};

export const getAllCustomNotification = (token) => (dispatch) => {
  axios
    .get(api_url + `/api/admin/notification/getAllCustomNotifications`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        console.log("ON sSUCESSS==", res.data.data.notification )
        dispatch({ type: GET_CUSTOM_NOTIFICATION, payload: res.data.data.notification });
      }
    })
    .catch((err) => console.log(err));
};

export const updateCustomNotification = (notificationData, token) => () => {
  console.log("notificationData-----", notificationData)
  axios
    .post(
      api_url + `/api/admin/notification/update`,
       notificationData ,
      {
        headers: { "x-access-token": token },
      }
    )
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

// export const sendNotificationToUser = (type, userId, token) => () => {

//   axios
//   .post(
//     api_url + `/api/admin/notification/send`,
//     { type, userId },
//     {
//       headers: { "x-access-token": token },
//     }
//   )
//   .then((res) => {
//     if (res.data.error) return toast.error(res.data.error);

//     if (res.data.success) {
//       toast.success(res.data.msg);
//     }
//   })
//   .catch((err) => console.log(err));

// };

export const sendNotificationToMultipleUser = (type, arrayofid, token) => () => {
  console.log("API CALLED")
  axios
  .post(
    api_url + `/api/admin/notification/sendAll`,
    { type, arrayofid },
    {
      headers: { "x-access-token": token },
    }
  )
  .then((res) => {
    if (res.data.error) return toast.error(res.data.error);

    if (res.data.success) {
      toast.success(res.data.msg);
    }
  })
  .catch((err) => console.log(err));

};