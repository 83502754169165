import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import ProfileDetails from "./ProfileDetails";
import ProfileImg from "./ProfileImg";
import { getProfile, submitProfile } from "../../../actions/dashboardActions";
import { validateProfile } from "../../../validations/profile";
import { api_url } from "../../../utils/api";
import "./styles.scss";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      image: "",
      name: "",
      phoneNumber: "",
      email: "",
      created_at: "",
      imagePreviewUrl: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    if (token) this.props.getProfile(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.adminProfile) {
      this.setState({
        name: nextProps.dashboard.adminProfile.name,
        email: nextProps.dashboard.adminProfile.email,
        phoneNumber: nextProps.dashboard.adminProfile.phoneNumber,
        created_at: nextProps.dashboard.adminProfile.created_at,
        image: nextProps.dashboard.adminProfile.image,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const validate = validateProfile(this.state);
    if (validate) return toast.error(validate);

    const token = localStorage.getItem("tizr_token");

    const profile = new FormData();

    profile.append("image", this.state.image);
    profile.append("name", this.state.name);
    profile.append("phoneNumber", this.state.phoneNumber);
    profile.append("email", this.state.email);

    this.props.submitProfile(profile, token);
  };

  render() {
    let imagePreview = api_url + "/default.png";

    if (this.state.image) imagePreview = `${api_url}/${this.state.image}`;
    if (this.state.imagePreviewUrl) imagePreview = this.state.imagePreviewUrl;

    return (
      <React.Fragment>
        <div
          className="profile-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <h5>Manage Profile</h5>
              <hr />
            </div>

            <form onSubmit={this.handleSubmit} encType="multipart/form-data">
              <div className="row mx-0">
                <div className="col-md-3 pl-0">
                  <div className="profile-img">
                    <ProfileImg
                      imagePreview={imagePreview}
                      handleImageChange={this.handleImageChange}
                      name={this.state.name}
                      email={this.state.email}
                      phoneNumber={this.state.phoneNumber}
                      created_at={this.state.created_at}
                    />
                  </div>
                </div>
                <div className="col-md-9 pr-0">
                  <div className="profile-details">
                    <ProfileDetails
                      name={this.state.name}
                      email={this.state.email}
                      phoneNumber={this.state.phoneNumber}
                      handleChange={this.handleChange}
                      handleSubmit={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  dashboard: PropTypes.object.isRequired,
  submitProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getProfile, submitProfile })(Profile);
