export const IS_LOADING = "IS_LOADING";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const GET_PROFILE = "GET_PROFILE";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_EARNINGS = "GET_EARNINGS";
export const GET_CMS = "GET_CMS";
export const GET_FAQ = "GET_FAQ";
export const ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS";
export const ALL_WITHDRAW_REQUESTS = "ALL_WITHDRAW_REQUESTS";
export const GET_WITHDRAW = "GET_WITHDRAW";
export const GET_CUSTOM_NOTIFICATION = "GET_CUSTOM_NOTIFICATION";
export const ALL_ADMIN_NOTIFICATIONS = "ALL_ADMIN_NOTIFICATIONS";
