import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import {
  getAllWithdrawRequest,
  getWithdrawDetail,
  updatePaymentStatus,
} from "../../../actions/dashboardActions";
import ViewWithdrawModal from "../../common/Modal/ViewWithdraw";
import Pagination from "../../common/Pagination/Pagination";
import "./styles.scss";

class ManageUser extends Component {
  constructor() {
    super();
    this.state = {
      query: "",
      pageLimit: 10,
      currentPage: 1,
      showViewWithdrawModal: false,
      requestType: "",
      render: false,
      sortType: "",
    };
  }

  componentDidMount() {
    let { currentPage, pageLimit, requestType } = this.state;

    const token = localStorage.getItem("tizr_token");
    this.props.getAllWithdrawRequest(currentPage, pageLimit, !requestType  ? "" : requestType, token);
  };


  viewWithdrawModalClicked = (id) => {
    this.setState({ showViewWithdrawModal: true, id });
    const token = localStorage.getItem("tizr_token");
    this.props.getWithdrawDetail(id, token);
  };
  handlePay = (withdrawId) => {
    this.setState( {showViewWithdrawModal: false, id: '' });
    const token = localStorage.getItem("tizr_token");
    this.props.updatePaymentStatus(withdrawId, this.state.id, token);
  };


  handlePageClick = (page) => {
    this.setState({ currentPage: page });
  };


  handleViewWithdrawModalClose = () => {
    this.setState({ showViewWithdrawModal: false });
  };

  handleSortRequest = (e) => {
    this.setState({ requestType: e.target.value });
    let { currentPage, pageLimit } = this.state;
    const token = localStorage.getItem("tizr_token");

    this.props.getAllWithdrawRequest(
      currentPage,
      pageLimit,
      e.target.value,
      token
    );
  };

  handleSortUserWithdrawRequest = (value) => {
    console.log("value", value)
    this.setState({sortType: value});
   // console.log("this.props.dashboard.withdrawDetail", this.props.dashboard.withdrawDetail)
    const date = new Date(); date.setDate(date.getDate() - 7); 
    if(value === 'Latest'){
      const filter = this.props.dashboard.withdrawDetail.filter((el) => moment(el.withdrawSendAt).format("MMM DD, YYYY") >= moment(date).format("MMM DD, YYYY"));
      this.props.dashboard.withdrawDetail = filter;
      this.setState({render: !this.state.render});
    }
    else if(value === 'Oldest'){
      const filter = this.props.dashboard.withdrawDetail.filter((el) => moment(el.withdrawSendAt).format("MMM DD, YYYY") < moment(date).format("MMM DD, YYYY"));
      this.props.dashboard.withdrawDetail = filter;
      this.setState({render: !this.state.render});
    }
    else if(value === 'All'){
      const token = localStorage.getItem("tizr_token");
      this.props.getWithdrawDetail(this.state.id, token);
      this.setState({render: !this.state.render});
    }
   
 
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="manage-user-withdrawls-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <h5>Manage Withdrawls</h5>
          <hr />
          <div className="manage-user-withdrawls">
            <div className="row mx-0">
              <div className="col-md-7 pl-0"></div>
              <div className="col-md-3 px-0 form-group">
              <select
                  name="userType"
                  value={this.state.requestType}
                  onChange={this.handleSortRequest}
                  className="form-control shadow-none"
                >  <option value="">Sort</option>
                   <option value="Latest">Latest</option>
                  <option value="Oldest">Oldest</option>
                </select>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.dashboard.withdrawRequests &&
                  this.props.dashboard.withdrawRequests.length > 0 &&
                  this.props.dashboard.withdrawRequests.map((u, index) => (
                    <tr key={u._id}>
                      <td>{index + 1}</td>
                      <td>{u.userId && u.userId.username ? u.userId.username : '-'}</td>
                      <td>{u.userId && u.userId.phoneNumber ? u.userId.phoneNumber : '-'}</td>
                      <td>{u.referredBy ? u.referredBy.username : "-"}</td>
                      {/* <td>
                        <span
                          className={u.isUserBlocked ? "suspended" : "active"}
                        >
                          {u.isUserBlocked ? "suspended" : "active"}
                        </span>
                      </td> */}
                      {/* <td>{moment(u.created_at).format("MMM DD, YYYY")}</td> */}
                      <td>
                        <Link
                          to="#"
                          onClick={() => this.viewWithdrawModalClicked(u.userId._id)}
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
              <div className="col-md-6 pl-0">
                {!this.state.query && (
                  <Pagination
                    count={0}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <ViewWithdrawModal
          showViewWithdrawModal={this.state.showViewWithdrawModal}
          handleViewWithdrawModalClose={this.handleViewWithdrawModalClose}
          details={this.props.dashboard.withdrawDetail}
          key={this.state.render}
          handleSortUserWithdrawRequest={this.handleSortUserWithdrawRequest}
          handlePay={this.handlePay}
          sortType={this.state.sortType}
        />
      </React.Fragment>
    );
  }
}

ManageUser.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllWithdrawRequest: PropTypes.func.isRequired,
  getWithdrawDetail: PropTypes.func.isRequired,
  updatePaymentStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {getAllWithdrawRequest, getWithdrawDetail, updatePaymentStatus })(ManageUser);
