import React from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
} from "react-pro-sidebar";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import VienMenuItem from "./VienMenuItem";
import VienSubMenu from "./VienSubMenu";
import VienSubMenuItem from "./VienSubmenuItem";
import { logoutUser } from "../../../actions/authActions";
import "./styles.scss";

const Sidebar = (props) => {
  const handleLogout = () => {
    props.logoutUser(props.history);
  };

  return (
    <React.Fragment>
      <ProSidebar collapsed={props.collapsed} onToggle={props.handleToggle}>
        <SidebarHeader>
          {props.collapsed ? (
            <h3>
              TZ
              {/* <img className="logo-small" src="/logo192.png" alt="logo" /> */}
            </h3>
          ) : (
            <h3>
              TIZR
              {/* <img src="/logo.png" alt="logo" /> */}
            </h3>
          )}
        </SidebarHeader>

        <SidebarContent>
          {/* Dashboard */}

          <Menu iconShape="square" popperArrow={true}>
            <VienMenuItem
              icon="tachometer"
              collapsed={props.collapsed}
              name="Dashboard"
              link="/admin/dashboard"
            />

            {/* Manage Users */}

            <VienMenuItem
              icon="user-plus"
              collapsed={props.collapsed}
              name="Users"
              link="/admin/users"
            />

            {/* Manage Earnings */}

            <VienMenuItem
              icon="dollar"
              collapsed={props.collapsed}
              name="Earnings"
              link="/admin/earnings"
            />

            <VienMenuItem
              icon="envelope-o"
              collapsed={props.collapsed}
              name="Custom Notification"
              link="/admin/notifications"
            />
             <VienMenuItem
              icon="bell-o"
              collapsed={props.collapsed}
              name="Notifications"
              link="/admin/adminNotifications"
            />

            {/* Manage Withdrawls */}

            <VienMenuItem
              icon="sign-out"
              collapsed={props.collapsed}
              name="Withdrawls"
              link="/admin/withdrawls"
            />

            {/* Manage FAQ */}

            <VienSubMenu
              collapsed={props.collapsed}
              name="FAQ"
              icon="file-text"
            >
              <VienSubMenuItem name="Add FAQ" link="/admin/faq/add" />
              <VienSubMenuItem name="Manage FAQ" link="/admin/faq/manage" />
            </VienSubMenu>

            {/* CMS */}

            <VienSubMenu
              collapsed={props.collapsed}
              name="CMS"
              icon="file-text"
            >
              <VienSubMenuItem name="About" link="/admin/about" />
              <VienSubMenuItem name="Privacy Policy" link="/admin/privacy-policy" />
              <VienSubMenuItem name="Terms & conditions" link="/admin/terms-and-condition" />
            </VienSubMenu>

            {/* Profile */}

            <VienMenuItem
              icon="user"
              collapsed={props.collapsed}
              name="Profile"
              link="/admin/profile"
            />

            {/* Settings */}

            <VienMenuItem
              icon="cog"
              collapsed={props.collapsed}
              name="Settings"
              link="/admin/settings"
            />

            {/* Logout */}

            <VienMenuItem
              icon="sign-out"
              collapsed={props.collapsed}
              name="Logout"
              link="#"
              handleClick={handleLogout}
            />
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Sidebar));
