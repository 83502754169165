import React, { Component } from "react";
import "./styles.scss";
import { htmlToText } from 'html-to-text';
import { getCMS } from "../../../actions/dashboardActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
       text: "",
    };
  }
  
  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    this.props.getCMS(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.terms) {
        const convertedText = htmlToText(nextProps.dashboard.cms.about, {
            wordwrap: false
          });
      this.setState({
        text: convertedText,
      });
    }
  }

  render() {
    return (
      <div className="container-fluid policy">
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h4>Terms & Conditions</h4>
            <hr />
          </div>
          <div className="col-md-12 px-0">
            <p>What’s in these terms?
This index is designed to help you understand some of the key updates we’ve made to our Terms of Service (Terms). We hope this serves as a useful guide, but please ensure you read the Terms in full.

Welcome to YouTube!
This section outlines our relationship with you. It includes a description of the Service, defines our Agreement, and names your service provider.

Who May Use the Service?
This section sets out certain requirements for use of the Service, and defines categories of users.

Your Use of the Service
This section explains your rights to use the Service, and the conditions that apply to your use of the Service. It also explains how we may make changes to the Service.

Your Content and Conduct
This section applies to users who provide Content to the Service. It defines the scope of the permissions that you grant by uploading your Content, and includes your agreement not to upload anything that infringes on anyone else’s rights. 

Account Suspension and Termination
This section explains how you and YouTube may terminate this relationship.

About Software in the Service
This section includes details about software on the Service.

Other Legal Terms
This section includes our service commitment to you. It also explains that there are some things we will not be responsible for.

About this Agreement
This section includes some further important details about our contract, including what to expect if we need to make changes to these Terms; or which law applies to them.
Terms of Service
Dated: March 17, 2021

Welcome to YouTube!
We have updated our Terms of Service on March 17, 2021 to clarify how children of all ages may use the Service if enabled by a parent or legal guardian.
Introduction
Thank you for using the YouTube products, services and features provided to you from the platform (collectively, the “Service”).  

Our Service

The Service allows you to discover, watch and share videos and other content, provides a forum for people to connect, inform, and inspire others across the globe, and acts as a distribution platform for original content creators and advertisers large and small. We provide lots of information about our products and how to use them in our Help Center. Among other things, you can find out about YouTube Kids, the YouTube Partner Program and YouTube Paid Memberships and Purchases (where available).You can also read all about enjoying content on other devices like your television, your games console, or Google Home.

Your Service Provider

The entity providing the Service is Google LLC, a company operating under the laws of Delaware, located at 1600 Amphitheatre Parkway, Mountain View, CA 94043 (referred to as “YouTube”, “we”, “us”, or “our”). References to YouTube’s “Affiliates” in these terms means the other companies within the Alphabet Inc. corporate group (now or in the future).

Applicable Terms
Your use of the Service is subject to these terms, the YouTube Community Guidelines and the Policy, Safety and Copyright Policies which may be updated from time to time (together, this "Agreement"). Your Agreement with us will also include the Advertising on YouTube Policies if you provide advertising or sponsorships to the Service or incorporate paid promotions in your content. Any other links or references provided in these terms are for informational use only and are not part of the Agreement.

Please read this Agreement carefully and make sure you understand it. If you do not understand the Agreement, or do not accept any part of it, then you may not use the Service.

By accepting this Agreement, you affirm that you are 18 years of age or above and are fully competent to enter into this Agreement, and to abide by and comply with this Agreement. In case you are below 18 years of age, the restrictions and requirements below apply.

Who may use the Service?
Age Requirements
You must be at least 13 years old to use the Service; however, children of all ages may use the Service and YouTube Kids (where available) if enabled by a parent or legal guardian. 

Permission by Parent or Guardian

If you are considered a minor in your country, you represent that you have your parent or guardian’s permission to use the Service. Please have them read this Agreement with you.

If you are a parent or legal guardian of a minor in your country, by allowing your child to use the Service, you are subject to the terms of this Agreement and responsible for your child’s activity on the Service. You can find tools and resources to help you manage your family’s experience on YouTube (including how to enable a child under the age of 13 to use the Service and YouTube Kids) in our Help Center and through Google’s Family Link.

Businesses
If you are using the Service on behalf of a company or organisation, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.

Your Use of the Service
Content on the Service
The content on the Service includes videos, audio (for example music and other sounds), graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, service marks, or logos), interactive features, software, metrics, and other materials (collectively, "Content”).

Content is the responsibility of the person or entity that provides it to the Service. YouTube is under no obligation to host or serve Content. If you see any Content you believe does not comply with this Agreement, including by violating the Community Guidelines or the law, you can report it to us.

Google Accounts and YouTube Channels
You can use parts of the Service, such as browsing and searching for Content, without having a Google account. However, you do need a Google account to use some features. With a Google account, you may be able to like videos, subscribe to channels, create your own YouTube channel, and more. You can follow these instructions to create a Google account.

Creating a YouTube channel will give you access to additional features and functions, such as uploading videos, making comments or creating playlists (where available). Here are some details about how to create your own YouTube channel.

To protect your Google account, keep your password confidential. You should not reuse your Google account password on third-party applications. Learn more about keeping your Google account secure, including what to do if you learn of any unauthorised use of your password or Google account.

Your Information
Our Privacy Policy explains how we treat your personal data and protect your privacy when you use the Service. The YouTube Kids Privacy Notice provides additional information about our privacy practices that are specific to YouTube Kids.

We will process any audio or audiovisual content uploaded by you to the Service in accordance with the YouTube Data Processing Terms, except in cases where you uploaded such content for personal purposes or household activities. Learn More.

Permissions and Restrictions
You may use the Service as made available to you, as long as you comply with this Agreement and applicable law. You may view or listen to Content for your personal, non-commercial use. You may also show YouTube videos through the embeddable YouTube player.

The following restrictions apply to your use of the Service. You are not allowed to:

access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from YouTube and, if applicable, the respective rights holders; 
circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including security-related features or features that (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Service or Content;
access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with YouTube’s robots.txt file; or (b) with YouTube’s prior written permission;
collect or harvest any information that might identify a person (for example, usernames), unless permitted by that person or allowed under section (3) above;
use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;
cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase a video’s views, likes, or dislikes, or to increase a channel’s subscribers, or otherwise manipulate metrics in any manner;
misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless, vexatious, or frivolous submissions;
run contests on or through the Service that do not comply with YouTube’s contest policies and guidelines;
use the Service to view or listen to Content other than for personal, non-commercial use (for example, you may not publicly screen videos or stream music from the Service); or
use the Service to (a) sell any advertising, sponsorships, or promotions placed on, around, or within the Service or Content, other than those allowed in the Advertising on YouTube policies (such as compliant product placements); or (b) sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content from the Service or where Content from the Service is the primary basis for such sales (for example, selling ads on a webpage where YouTube videos are the main draw for users visiting the webpage).
Reservation

Using the Service does not give you ownership of or rights to any aspect of the Service, including user names or any other Content posted by others or YouTube).

Changes to the Service

YouTube is constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, in order to make performance or security improvements, change functionality and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user.  Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our Service that will have an adverse impact on the use of our Service. However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability of our Service, prevent abuse, or comply with legal requirements. 

Your Content and Conduct
Uploading Content

If you have a YouTube channel, you may be able to upload Content to the Service. You may use your Content to promote your business or artistic enterprise. If you choose to upload Content, you must not submit to the Service any Content that does not comply with this Agreement (including the YouTube Community Guidelines) or the law. For example, the Content you submit must not include third-party intellectual property (such as copyrighted material) unless you have permission from that party or are otherwise legally entitled to do so. You are legally responsible for the Content you submit to the Service. We may use automated systems that analyze your Content to help detect infringement and abuse, including spam and malware.

Rights you Grant

You retain ownership rights in your Content. However, we do require you to grant certain rights to YouTube and other users of the Service, as described below.

License to YouTube

By providing Content to the Service, you grant to YouTube a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use that Content (including to reproduce, distribute, prepare derivative works, display and perform it). YouTube may only use that Content in connection with the Service and YouTube’s (and its successors’ and Affiliates) business, including for the purpose of promoting and redistributing part or all of the Service.

License to Other Users

You also grant each other user of the Service a worldwide, non-exclusive, royalty-free license to access your Content through the Service, and to use that Content, including to reproduce, distribute, prepare derivative works, display and perform it, only as enabled by a feature of the Service (such as video playback or embeds). For clarity, this license does not grant any rights or permissions for a user to make use of your Content independent of the Service.

Duration of License

The licenses granted by you continue for a commercially reasonable period of time after you remove or delete your Content from the Service. You understand and agree, however, that YouTube may retain, but not display, distribute, or perform, server copies of your videos that have been removed or deleted. 

Removing Your Content

You may remove your Content from the Service at any time. You also have the option to make a copy of your Content before removing it. You must remove your Content if you no longer have the rights required by these terms.

Removal of Content By YouTube

If we reasonably believe that any Content is in breach of this Agreement or may cause harm to YouTube, our users, or third parties, we may remove or take down that Content in our discretion. We will notify you with the reason for our action unless we reasonably believe that to do so: (a) would breach the law or the direction of a legal enforcement authority or would otherwise risk legal liability for YouTube or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, YouTube or our Affiliates. You can learn more about reporting and enforcement, including how to appeal on the Troubleshooting page of our Help Center.

Copyright Protection

We provide information to help copyright holders manage their intellectual property online in our YouTube Copyright Center. If you believe your copyright has been infringed on the Service, please send us a notice.

We respond to notices of alleged copyright infringement according to the process in our YouTube Copyright Center, where you can also find information about how to resolve a copyright strike. YouTube's policies provide for the termination, in appropriate circumstances, of repeat infringers’ access to the Service.

Account Suspension & Termination
Terminations by You
You may stop using the Service at any time. Follow these instructions to delete the Service from your Google Account, which involves closing your YouTube channel and removing your data. You also have the option to download a copy of your data first.

Terminations and Suspensions by YouTube for Cause

YouTube may suspend or terminate your use of the Services, your Google account, or your Google account’s access to all or part of the Service if (a) you materially or repeatedly breach this Agreement; (b) we are required to do so to comply with a legal requirement or a court order; or (c) we believe there has been conduct that creates (or could create) liability or harm to any user, other third party, YouTube or our Affiliates.

Terminations by YouTube for Service Changes

YouTube may terminate your use of the Services, or your Google account’s access to all or part of the Service if YouTube believes, in its sole discretion, that provision of the Service to you is no longer commercially viable. 

Notice for Termination or Suspension

We will notify you with the reason for termination or suspension by YouTube unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for YouTube or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, YouTube or our Affiliates. Where YouTube is terminating your use for Service changes, where reasonably possible, you will be provided with sufficient time to export your Content from the Service.

Effect of Account Suspension or Termination

If your Google account is terminated or your Google account’s access to the Service is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such use. If you believe your Google account has been terminated in error, you can appeal using this form.

About Software in the Service
Downloadable Software
When the Service requires or includes downloadable software (such as the YouTube Studio application), you give permission for that software to update automatically on your device once a new version or feature is available, subject to your device settings. Unless that software is governed by additional terms which provide a license, YouTube gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by YouTube as part of the Service. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Service as provided by YouTube, in the manner permitted by this Agreement. You are not allowed to copy, modify, distribute, sell, sublicense or lease any part of the software, or to reverse-engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have YouTube’s written permission.

Open Source
Some software used in our Service may be offered under an open source, royalty-free license that we provide to you. There may be provisions in an open source license that expressly override some of these terms, so please be sure to read those licenses.  

Other Legal Terms
Warranty Disclaimer
OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND YOUTUBE DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.

Limitation of Liability

EXCEPT AS REQUIRED BY APPLICABLE LAW, YOUTUBE, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:

ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
ANY UNAUTHORIZED USE OF THE SERVICE;
ANY INTERRUPTION OR CESSATION OF THE SERVICE;
ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
ANY CONTENT WHETHER SUBMITTED BY A USER OR YOUTUBE, INCLUDING YOUR USE OF CONTENT; AND/OR
THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.

TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUTUBE AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT YOUTUBE HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO YOUTUBE, OF THE CLAIM AND (B) USD $500, WHICHEVER IS HIGHER. 

</p>
           
          </div>
        </div>
      </div>
    );
  }
}
Terms.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getCMS })(Terms);
