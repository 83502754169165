import isEmpty from "is-empty";

export const validateProfile = (...props) => {
  if (isEmpty(props[0].name)) return "Please enter your name";
  if (props[0].name.length < 3) return "Name must be atleast 3 characters";
  if (props[0].name.length > 255) return "Name must be atmost 255 characters";

  if (isEmpty(props[0].email)) return "Please enter your email";

  if (isEmpty(props[0].phoneNumber)) return "Please enter your phone number";
  if (props[0].phoneNumber.length !== 10) return "Invalid phone number";
};
