import React from "react";
import moment from "moment";
import { Modal, ListGroup } from "react-bootstrap";
import Button from "../../common/Button/Button";
import "./styles.scss";

const ViewWithdraw = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.showViewWithdrawModal}
        onHide={props.handleViewWithdrawModalClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
              <>
              <div className="manage-user-withdrawls">
            <div className="row mx-0">
              <div className="col-md-7 pl-0"></div>
              <div className="col-md-3 px-0 form-group">
              <select
                  name="userType"
                  value={props.sortType}
                  onChange={(e) => props.handleSortUserWithdrawRequest(e.target.value)}
                  className="form-control shadow-none"
                >  <option value="">Sort</option>
                   <option value="All">All</option>
                   <option value="Latest">Latest</option>
                  <option value="Oldest">Oldest</option>
                </select>
              </div>
            </div>
            </div>
               <table className="table">
               
              <thead>
                <tr >
                  <th>Id</th>
                  <th>Amount</th>
                  <th>Paypal Email</th>
                  <th>Request Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {console.log("modal--", props.details)}
              {props.details.length > 0 && props.details.map((el, i) => (
                    <tr key={el._id}>
                    <td>{i + 1}</td>
                    <td>{el.withdrawAmount}</td>
                    <td>{el.paypalEmail}</td>
                    <td>{moment(el.withdrawSendAt).format("MMM DD, YYYY")}</td>
                    <td>
                      <span
                        className={el.status ? "active" : "suspended"}
                      >
                        {el.status ? "paid" : "unpaid"}
                      </span>
                    </td>
                    <td>
                    {el.status ? 
                    (<Button
                  type="button"
                  className="btn btn-primary add-new-user-btn shadow-none disabled disabledClass"
                  value='Paid'

                   />) : ( <Button
                    type="button"
                    className="btn btn-primary add-new-user-btn shadow-none"
                    value='Pay'
                    handleClick={() => props.handlePay(el._id)}
                  /> ) }
                 
                </td>
                  
                    {/* <td>
                      <Link
                        to="#"
                        onClick={() => this.viewWithdrawModalClicked(u.userId._id)}
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                      <Link
                        to="#"
                        onClick={() => this.editUserModalClicked(u._id)}
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                      
                      <Link to="#" onClick={() => this.deleteUserModalClicked(u._id)}>
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </Link>
                    </td> */}
                  </tr>
                   
                  ))}
              </tbody>
            </table>
                {/* <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  ID <span>{el._id}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  RequestAmount <span>{el.withdrawAmount}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Paypal Email <span>{el.paypalEmail}</span>
                </ListGroup.Item>
           
               

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Created At{" "}
                  <span>
                    {moment(el.withdrawSendAt).format("MMM DD, YYYY")}
                  </span>
                </ListGroup.Item> */}
              </>
          
          </ListGroup>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ViewWithdraw;
