import React, { Component } from "react";
import "./styles.scss";
import { connect } from "react-redux";

class YoutubePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
       text: "",
    };
  }

  render() {
    return (
      <div className="container-fluid policy">
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h4>Privacy Policy</h4>
            <hr />
          </div>
          <div className="col-md-12 px-0">
            <p>When this policy applies
This Privacy Policy applies to all of the services offered by Google LLC and its affiliates, including YouTube, Android, and services offered on third-party sites, such as advertising services. This Privacy Policy doesn’t apply to services that have separate privacy policies that do not incorporate this Privacy Policy.

This Privacy Policy doesn’t apply to:

The information practices of other companies and organizations that advertise our services
Services offered by other companies or individuals, including products or sites that may include Google services, be displayed to you in search results, or be linked from our services
Changes to this policy
We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review. If changes are significant, we’ll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).

Other useful resources
The following links highlight useful resources for you to learn more about our practices and privacy settings.

Your Google Account is home to many of the settings you can use to manage your account
Privacy Checkup guides you through key privacy settings for your Google Account
Google’s safety center helps you learn more about our built-in security, privacy controls, and tools to help set digital ground rules for your family online
Privacy & Terms provides more context regarding this Privacy Policy and our Terms of Service
Technologies includes more information about:
How Google uses cookies
Technologies used for Advertising
How Google uses pattern recognition to recognize things like faces in photos
How Google uses information from sites or apps that use our services
</p>     
          </div>
        </div>
      </div>
    );
  }
}
YoutubePolicy.propTypes = {
};

const mapStateToProps = (state) => ({
 
});

export default connect(mapStateToProps, { })(YoutubePolicy);
