import axios from "axios";
import { toast } from "react-toastify";
import { IS_AUTHENTICATED } from "./types";
import { api_url } from "../utils/api";

export const adminLogin = (admin, history) => (dispatch) => {
  axios
    .post(api_url + "/api/admin/login", admin)
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        localStorage.setItem("tizr_token", res.data.data.token);
        dispatch({ type: IS_AUTHENTICATED, payload: true });

        history.push("/admin/dashboard");
      }
    })
    .catch((err) => console.log(err));
};

export const forgotPassword = (email, history) => (dispatch) => {
  axios
    .post(api_url + "/api/admin/forgot-password", { email })
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
      }
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (data, history) => (dispatch) => {
  axios
    .post(api_url + "/api/admin/reset-password", data)
    .then((res) => {
      if (res.data.error) return toast.error(res.data.error);

      if (res.data.success) {
        toast.success(res.data.msg);
        history.push("/admin/login");
      }
    })
    .catch((err) => console.log(err));
};

// Logout User
export const logoutUser = (history) => (dispatch) => {
  localStorage.removeItem("tizr_token");
  toast.success("Logged out successfully");
  history.push("/admin/login");
};
