import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/Button";
import "./styles.scss";

const DeleteModal = (props) => {
    {console.log("props", props)}
  return (
    <React.Fragment>
      <Modal
        show={props.showDeleteUserModal}
         onHide={props.handleDeleteModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p >Are You sure you want to delete ?</p>
          <Button
              value="delete"
              className="btn btn-primary btn-block add-user-btn shadow-none"
              handleClick={props.handleYesBtnClick}
            />
       
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
