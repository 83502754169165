import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Pagination from "../../common/Pagination/Pagination";
import Statistics from "./Statistics";
import { getAllUsers, getAllWithdrawRequest } from "../../../actions/dashboardActions";
import "./styles.scss";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLimit: 10,
      currentPage: 1,
      requestType: "",
      userType: "",
      socialType: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    let { currentPage, pageLimit, requestType } = this.state;

   this.props.getAllUsers(currentPage, pageLimit,"", "", token);

    this.props.getAllWithdrawRequest(currentPage, pageLimit, !requestType  ? "" : requestType, token);
  }

  handlePageClick = (page) => {
    this.setState({ currentPage: page });

    let { pageLimit, userType, socialType } = this.state;

    const token = localStorage.getItem("tizr_token");
    this.props.getAllUsers(
      page,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };
  render() {
    let { users, withdrawRequests } = this.props.dashboard;
    let { adminProfile } = this.props.dashboard;
    let totalEarnings = 0;

    let userImage =
      adminProfile && adminProfile.image !== "default.png"
        ? adminProfile.image
        : "/img/default.png";

    console.log(userImage);

    if (users && users.length > 0) {
      users.forEach((user) => {
        if (user.social && user.social.length > 0) {
          user.social.forEach((u) => {
            let user_earnings = parseFloat(u.connectEarning) || 0 + parseFloat(u.postEarning) || 0 + parseFloat(u.referralEarning) || 0;
            totalEarnings += user_earnings;
          });
        }
      });
    }

    return (
      <div
        className="container-fluid dashboard"
        style={{ paddingLeft: this.props.collapsed ? "110px" : "310px" }}
      >
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h3>Dashboard</h3>
            <hr />

            <Statistics
              totalUsers={users ? users.length : 0}
              totalEarnings={totalEarnings.toFixed(5)}
              withdrawRequests={withdrawRequests ? withdrawRequests.length : 0}
            />
          </div>
        </div>

        <div className="recent-joined-customers">
          <h4>Recent Users</h4>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Contact No</th>
                <th>Referred By</th>
                <th>Status</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {this.props.dashboard.users &&
                this.props.dashboard.users.map((u, index) => (
                <>
                  <tr key={u._id}>
                      <td>{index + 1}</td>
                      <td>{u.name}</td>
                      <td>{u.username}</td>
                      <td>{u.email}</td>
                      <td>${parseFloat(u.referralEarning)}</td>
                  
                      <td>{u.referredBy ? u.referredBy.username : "-"}</td>
                     
                    </tr>
                </>
                ))}
            </tbody>
          </table>
          <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
              <div className="col-md-6 pl-0">
                
                {console.log("pagination===>", this.state.query)}
                {!this.state.query && (
                  <Pagination
                    count={this.props.dashboard.users.length}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="recent-orders">
              <h4>Recent Withdrawl Requests</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Contact No</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>

          <div className="col-md-4">
            <div className="profile">
              <img src={userImage} alt="profile-pic" />
              <p># {adminProfile && adminProfile._id}</p>
              <h4>{adminProfile && adminProfile.name}</h4>
              <h5>
                <i className="fa fa-envelope"></i>{" "}
                {adminProfile && adminProfile.email}
              </h5>
              <h5>
                <i className="fa fa-phone"></i>{" "}
                {adminProfile && adminProfile.phoneNumber}
              </h5>
              <Link to="/business/profile">Edit</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getAllWithdrawRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getAllUsers, getAllWithdrawRequest })(Dashboard);
