import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import FormInput from "../../common/Form-Input/FormInput";
import { forgotPassword } from "../../../actions/authActions";
import { validateForgotPassword } from "../../../validations/auth";
import "./styles.scss";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (localStorage.getItem("tizr_token"))
      props.history.push("/admin/dashboard");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = validateForgotPassword(email);
    if (result) return toast.error(result);

    props.forgotPassword(email, props.history);
    setEmail("");
  };

  return (
    <React.Fragment>
      <div className="admin-forgot-password">
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-md-5 mx-auto">
              <div className="card">
                <div className="card-body">
                  <h5>Forgot Password</h5>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      icon="fa fa-envelope icon"
                      type="text"
                      name="email"
                      placeholder="Username, Email or Phone Number"
                      value={email}
                      handleChange={(e) => setEmail(e.target.value)}
                    />

                    <div className="form-group mb-0 mt-4">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-dark btn-block forgot-password-btn shadow-none"
                      />
                    </div>

                    <div className="form-group mb-0 mt-3">
                      <Link to="/admin/login">Back to Login</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { forgotPassword })(
  withRouter(ForgotPassword)
);
