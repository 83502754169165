import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";

import Search from "../../common/Search/Search";
import UpdateFAQModal from "../../common/Modal/UpdateFAQ";
import { validateFAQ } from "../../../validations/cms";
import {
  getCMS,
  searchFAQ,
  markFAQActive,
  markFAQInactive,
  getFAQ,
  updateFAQ,
} from "../../../actions/dashboardActions";
import "./styles.scss";

class ManageFAQ extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      query: "",
      editor: "",
      question: "",
      answer: "",
      status: "",
      activeBtn: false,
      inactiveBtn: false,
      showUpdateFAQModal: false,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    this.props.getCMS(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.faq && nextProps.dashboard.faq.length > 0) {
      let data = nextProps.dashboard.faq;

      this.setState({
        question: data[0].question,
        answer: data[0].answer,
        status: data[0].status,
        activeBtn: data[0].status === "Active" ? true : false,
        inactiveBtn: data[0].status === "Inactive" ? true : false,
      });
    }
  }

  handleSearch = (query) => {
    this.setState({ query });

    const token = localStorage.getItem("tizr_token");
    this.props.searchFAQ(query, token);
  };

  markActive = (id) => {
    const token = localStorage.getItem("tizr_token");
    this.props.markFAQActive(id, token);
    this.props.getCMS(token);
  };

  markInactive = (id) => {
    const token = localStorage.getItem("tizr_token");
    this.props.markFAQInactive(id, token);
    this.props.getCMS(token);
  };

  handleUpdateFAQModalClose = () => {
    this.setState({ showUpdateFAQModal: false });
  };

  handleUpdateFAQModalClick = (id) => {
    this.setState({ showUpdateFAQModal: true, id });

    const token = localStorage.getItem("tizr_token");
    this.props.getFAQ(id, token);
  };

  handleInit = (editor) => {
    this.setState({ editor });
  };

  handleChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ answer: data });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioChange = (status) => {
    if (status === "Active") {
      this.setState({ status, activeBtn: true, inactiveBtn: false });
    } else {
      this.setState({ status, activeBtn: false, inactiveBtn: true });
    }
  };

  handleUpdateFAQSubmit = (e) => {
    e.preventDefault();

    let { id, question, answer, status } = this.state;

    let result = validateFAQ(question, answer);
    if (result) return toast.error(result);

    let faq = {
      id,
      question,
      answer,
      status,
    };

    const token = localStorage.getItem("tizr_token");
    this.props.updateFAQ(faq, token);

    this.setState({
      editor: "",
      question: "",
      answer: "",
      status: "",
      activeBtn: false,
      inactiveBtn: false,
      showUpdateFAQModal: false,
    });

    this.props.getCMS(token);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="manage-faq-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <h5>Manage FAQs</h5>
          <hr />
          <div className="manage-faq">
            <div className="row mx-0">
              <div className="col-md-7 pl-0"></div>
              <div className="col-md-5 pr-0">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search FAQ"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Status</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.dashboard.cms &&
                  this.props.dashboard.cms.faq &&
                  this.props.dashboard.cms.faq.length > 0 &&
                  this.props.dashboard.cms.faq.map((f, index) => (
                    <tr key={f._id}>
                      <td>{index + 1}</td>
                      <td>{f.question}</td>
                      <td>{f.answer.substring(0, 50) + "..."}</td>
                      <td>
                        <span className={f.status}>{f.status}</span>
                      </td>
                      <td>{moment(f.created_at).format("MMM DD, YYYY")}</td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => this.handleUpdateFAQModalClick(f._id)}
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                        {f.status === "Active" ? (
                          <Link to="#" onClick={() => this.markInactive(f._id)}>
                            <i className="fa fa-ban"></i>
                          </Link>
                        ) : (
                          <Link to="#" onClick={() => this.markActive(f._id)}>
                            <i className="fa fa-unlock"></i>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <UpdateFAQModal
          showUpdateFAQModal={this.state.showUpdateFAQModal}
          handleUpdateFAQModalClose={this.handleUpdateFAQModalClose}
          handleUpdateFAQSubmit={this.handleUpdateFAQSubmit}
          handleInputChange={this.handleInputChange}
          handleInit={this.handleInit}
          handleChange={this.handleChange}
          handleRadioChange={this.handleRadioChange}
          question={this.state.question}
          answer={this.state.answer}
          activeBtn={this.state.activeBtn}
          inactiveBtn={this.state.inactiveBtn}
        />
      </React.Fragment>
    );
  }
}

ManageFAQ.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
  searchFAQ: PropTypes.func.isRequired,
  markFAQActive: PropTypes.func.isRequired,
  markFAQInactive: PropTypes.func.isRequired,
  getFAQ: PropTypes.func.isRequired,
  updateFAQ: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getCMS,
  searchFAQ,
  markFAQActive,
  markFAQInactive,
  getFAQ,
  updateFAQ,
})(ManageFAQ);
