import React from "react";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";

const ProfileDetails = (props) => {
  return (
    <React.Fragment>
      <h4>Basic Details</h4>
      <div className="row mx-0">
        <div className="col-md-12 px-0">
          <FormInput
            icon="fa fa-user icon"
            size="12"
            type="text"
            name="name"
            placeholder="Name"
            value={props.name}
            handleChange={(e) => props.handleChange(e)}
          />
        </div>
      </div>

      <FormInput
        icon="fa fa-phone icon"
        size="12"
        type="number"
        name="phoneNumber"
        placeholder="Phone Number"
        value={props.phoneNumber}
        handleChange={(e) => props.handleChange(e)}
      />

      <FormInput
        icon="fa fa-envelope icon"
        size="12"
        type="email"
        name="email"
        placeholder="Email"
        value={props.email}
        handleChange={(e) => props.handleChange(e)}
      />

      <Button
        value="Update Profile"
        type="submit"
        className="btn btn-primary btn-block update-profile-btn shadow-none"
      />
    </React.Fragment>
  );
};

export default ProfileDetails;
