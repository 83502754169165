import React from "react";
import moment from "moment";
import { Modal, ListGroup } from "react-bootstrap";
import "./styles.scss";

const ViewUser = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.showViewUserModal}
        onHide={props.handleViewUserModalClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            {props.details && (
              <>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  ID <span>{props.details._id}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Name <span>{props.details.name}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Username <span>{props.details.username}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Email <span>{props.details.email}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Phone Number <span>{props.details.phoneNumber}</span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Status{" "}
                  <span>
                    {props.details.isUserBlocked ? "suspended" : "active"}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Referred By{" "}
                  <span>
                    {props.details.referredBy
                      ? props.details.referredBy.username
                      : "-"}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Referrals{" "}
                  <span>
                    {props.details.referredId &&
                    props.details.referredId.length > 0
                      ? props.details.referredId
                      : "-"}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Credits Earned{" "}
                  <span>$ {props.details.totalEarning && props.details.totalEarning} </span>
                </ListGroup.Item>
              

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Available Balance <span>$ {props.details.availableBalance && props.details.availableBalance} </span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  Created At{" "}
                  <span>
                    {moment(props.details.created_at).format("MMM DD, YYYY")}
                  </span>
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ViewUser;
