import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// import ProfileImg from "./ProfileImg";
import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";
import { validateCustomNotifications } from "../../../validations/customNotifications";
import { getAllCustomNotification, updateCustomNotification } from "../../../actions/dashboardActions";
import "./styles.scss";
import { api_url } from "../../../utils/api";

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      body: "",
      image: "",
      link:"",
      imagePreviewUrl: "",
      type1Title: "",
      type1Body: "",
      type2Body: "",
      type2Title: "",
      type3Body: "",
      type3Title: "",
      type4Title: "",
      type4Body: "",
      type5Title: "",
      type5Body: "",
      type6Title: "",
      type6Body: "",
      type7Title: "",
      type7Body: "",
      type8Title: "",
      type8Body: "",
      type9Title: "",
      type9Body: "",
      type10Title: "",
      type10Body: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    this.props.getAllCustomNotification(token);
  };

  handleImageChange = (e) => {
    // e.preventDefault();
      let reader = new FileReader();
      console.log(e.target.files[0].name)
      let file = e.target.files[0];
      reader.onloadend = () => {
          console.log("redaer.result", reader.result)
        this.setState({
          image: file || '',
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    };


  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dashboard.customNotifications
    ) {
      this.setState({
              body: nextProps.dashboard.customNotifications.body  || "",
              title: nextProps.dashboard.customNotifications.title || "",
              link:nextProps.dashboard.customNotifications.link || "",
            //  image: nextProps.dashboard.customNotifications.image || "",
            });
    
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddNotificationSubmit = (e) => {
    e.preventDefault();
   const { title, body, link} = this.state;
   let notificationData = {
     title,
     link,
     body
   }

    // let result = validateCustomNotifications(
    //  title,
    //  body
    // );
    // if (result) return toast.error(result);
      // const notificationData = new FormData();

      // // notificationData.append("image", this.state.image);
      // notificationData.append("link", this.state.link);
      // notificationData.append("title", this.state.title);
      // notificationData.append("body", this.state.body);
      // notificationData.append("type", 1);
      

    const token = localStorage.getItem("tizr_token");
    this.props.updateCustomNotification(notificationData, token);
  };

  render() {
    let imagePreview = api_url + "/default.png";

    if (this.state.image) imagePreview = `${api_url}/${this.state.image}`;
    if (this.state.imagePreviewUrl) imagePreview = this.state.imagePreviewUrl;
    return (
      <React.Fragment>
        <div
          className="manage-earnings-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <h5>Manage Custom Notifications</h5>
              <hr />
            </div>
          </div>

          <div className="manage-earnings">
              
            <form onSubmit={this.handleAddNotificationSubmit}>
              {/* <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 1
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type1Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type1Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type1Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type1Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2"> 
                  Notification Type 2
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type2Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type2Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type2Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type2Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2"> 
                  Notification Type 3 
                        </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type3Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type3Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type3Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type3Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 4
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type4Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type4Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type4Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type4Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 5
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type5Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type5Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type5Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type5Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 6
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type6Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type6Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type6Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type6Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 7
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type7Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type7Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type7Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type7Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 8
                         
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type8Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type8Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type8Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type8Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div> */}
              {/* <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">
                          Notification Type 9
                       
                       </h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    type="text"
                    name="type9Body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.type9Body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    type="text"
                    name="type9Title"
                    placeholder="Title"
                    className="form-control shadow-none"
                    value={this.state.type9Title}
                    handleChange={this.handleChange}
                  />
                </div>
              </div> */}

              <div className="row mx-0">
                <div className="col-md-12 px-0">
                  <h5 className="pt-2">
                          Notification
                       </h5>
                </div>
                
                <div className="col-md-12 px-0">
                <label>Link</label>
                  <FormInput
                    type="text"
                    name="link"
                    placeholder="Link"
                    className="form-control shadow-none"
                    value={this.state.link}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 px-0">
                <label>Body</label>
                  <FormInput
                    type="text"
                    name="body"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.body}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 px-0">
                <label>Title</label>
                  <FormInput
                    type="text"
                    name="title"
                    placeholder="Body"
                    className="form-control shadow-none"
                    value={this.state.title}
                    handleChange={this.handleChange}
                  />
                </div>
                {/* <div className="col-12 px-0">
                  <div className="profile-img">
                    <img
                      src={imagePreview}
                      alt="image"
                      id="edit-nutrition-img"
                    />

                    <div className="text-center mx-auto">
                      <label className="upload-image" htmlFor="upload-button">
                        Upload Image
                      </label>
                    </div>

                    <input
                      id="upload-button"
                      name="image"
                      type="file"
                      accept="image/*"
                      //value={props.image}
                      onChange={this.handleImageChange}
                    />
                  </div>
                </div> */}
            
              </div>

              <Button
                type="submit"
                value="Update Notification"
                className="btn btn-primary btn-block add-user-btn shadow-none mt-4"
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Notifications.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllCustomNotification: PropTypes.func.isRequired,
  updateCustomNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getAllCustomNotification, updateCustomNotification })(
  Notifications
);
