import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import FormInput from "../../common/Form-Input/FormInput";
import Button from "../../common/Button/Button";
import { validateEarnings } from "../../../validations/earnings";
import { getEarnings, updateEarnings } from "../../../actions/dashboardActions";
import "./styles.scss";

class Earnings extends Component {
  constructor() {
    super();
    this.state = {
      facebookConnectAmount: "",
      facebookStayAmount:'',
      facebookPostAmount: "",
      instagramConnectAmount: "",
      instagramStayAmount:'',
      twitterConnectAmount: "",
      twitterStayAmount:'',
      twitterPostAmount: "",
      googleConnectAmount: "",
      googleStayAmount:'',
      googlePostAmount: "",
      dailyLoginConsecutiveDays: "",
      dailyLoginAmount: "",
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    this.props.getEarnings(token);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dashboard.earnings &&
      nextProps.dashboard.earnings.length > 0 &&
      nextProps.dashboard.earnings[0].credit &&
      nextProps.dashboard.earnings[0].credit.length > 0
    ) {
      nextProps.dashboard.earnings[0].credit.forEach((e) => {
        if (e.socialType === "Facebook") {
          this.setState({
            facebookConnectAmount: e.socialConnectAmount || "",
            facebookPostAmount: e.postAmount || "",
            facebookStayAmount: e.stayAmount || '',
          });
        }

        if (e.socialType === "Google") {
          this.setState({
            googleConnectAmount: e.socialConnectAmount || "",
            googlePostAmount: e.postAmount || "",
            googleStayAmount: e.stayAmount || '',
          });
        }

        if (e.socialType === "Twitter") {
          this.setState({
            twitterConnectAmount: e.socialConnectAmount || "",
            twitterPostAmount: e.postAmount || "",
            twitterStayAmount: e.stayAmount || '',
          });
        }

        if (e.socialType === "Instagram") {
          this.setState({
            instagramConnectAmount: e.socialConnectAmount || "",
            instagramStayAmount: e.stayAmount || '',
          });
        }
      });
    }

    if (
      nextProps.dashboard.earnings &&
      nextProps.dashboard.earnings.length > 0 &&
      nextProps.dashboard.earnings[0].login
    ) {
      this.setState({
        dailyLoginConsecutiveDays:
          nextProps.dashboard.earnings[0].login.loginDays,
        dailyLoginAmount: nextProps.dashboard.earnings[0].login.amount,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddEarningSubmit = (e) => {
    e.preventDefault();

    let {
      facebookConnectAmount,
      facebookPostAmount,
      facebookStayAmount,
      instagramConnectAmount,
      instagramStayAmount,
      googleConnectAmount,
      googleStayAmount,
      googlePostAmount,
      twitterConnectAmount,
      twitterStayAmount,
      twitterPostAmount,
      dailyLoginConsecutiveDays,
      dailyLoginAmount,
    } = this.state;

    let result = validateEarnings(
      facebookConnectAmount,
      facebookStayAmount,
      facebookPostAmount,
      googleConnectAmount,
      googleStayAmount,
      googlePostAmount,
      twitterConnectAmount,
      twitterStayAmount,
      twitterPostAmount,
      dailyLoginConsecutiveDays,
      dailyLoginAmount
    );
    if (result) return toast.error(result);

    let earningData = [
      {
        socialConnectAmount: facebookConnectAmount,
        postAmount: facebookPostAmount,
        stayAmount: facebookStayAmount,
        socialType: "Facebook",
      },
      {
        socialConnectAmount: instagramConnectAmount,
        stayAmount: instagramStayAmount,
        socialType: "Instagram",
      },
      {
        socialConnectAmount: twitterConnectAmount,
        postAmount: twitterPostAmount,
        stayAmount: twitterStayAmount,
        socialType: "Twitter",
      },
      {
        socialConnectAmount: googleConnectAmount,
        postAmount: googlePostAmount,
        stayAmount: googleStayAmount,
        socialType: "Google",
      },
    ];

    let loginData = {
      loginDays: dailyLoginConsecutiveDays,
      amount: parseInt(dailyLoginAmount),
    };

    const token = localStorage.getItem("tizr_token");
    this.props.updateEarnings(earningData, loginData, token);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="manage-earnings-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <h5>Manage Earnings</h5>
              <hr />
            </div>
          </div>

          <div className="manage-earnings">
            <form onSubmit={this.handleAddEarningSubmit}>
            <div className="row mx-0">
                <div className="col-md-3 pl-0">

                  </div>
                  <div className="col-md-3 pl-0">
                    <h5>Social stay earning</h5>
                  </div>
                  <div className="col-md-3 pl-0">
                    <h5>Social connect earning</h5>
                  </div>
                  <div className="col-md-3 pl-0">
                    <h5>Social Post earning</h5>
                  </div>
                </div>  
              <div className="row mx-0">
                <div className="col-md-3 pl-0">
                  <h5 className="pt-2">Facebook</h5>
                </div>
                <div className="col-md-3 px-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="facebookStayAmount"
                    placeholder="Facebook Stay Amount"
                    className="form-control shadow-none"
                    value={this.state.facebookStayAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 ">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="facebookConnectAmount"
                    placeholder="Social Connect Amount"
                    className="form-control shadow-none"
                    value={this.state.facebookConnectAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 pr-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="facebookPostAmount"
                    placeholder="Post Amount"
                    className="form-control shadow-none"
                    value={this.state.facebookPostAmount}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-md-3 pl-0">
                  <h5 className="pt-2">Instagram</h5>
                </div>
                <div className="col-md-3 px-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="instagramStayAmount"
                    placeholder="Instagram Stay Amount"
                    className="form-control shadow-none"
                    value={this.state.instagramStayAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 pr-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="instagramConnectAmount"
                    placeholder="Social Connect Amount"
                    className="form-control shadow-none"
                    value={this.state.instagramConnectAmount}
                    handleChange={this.handleChange}
                  />
                </div>
               
              </div>

              <div className="row mx-0">
                <div className="col-md-3 pl-0">
                  <h5 className="pt-2">Google</h5>
                </div>
                <div className="col-md-3">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="googleStayAmount"
                    placeholder="Google Stay Amount"
                    className="form-control shadow-none"
                    value={this.state.googleStayAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 px-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="googleConnectAmount"
                    placeholder="Social Connect Amount"
                    className="form-control shadow-none"
                    value={this.state.googleConnectAmount}
                    handleChange={this.handleChange}
                  />
                </div>
              
                <div className="col-md-3 pr-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="googlePostAmount"
                    placeholder="Post Amount"
                    className="form-control shadow-none"
                    value={this.state.googlePostAmount}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-3 pl-0">
                  <h5 className="pt-2">Twitter</h5>
                </div>
                <div className="col-md-3 px-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="twitterStayAmount"
                    placeholder="Twitter Stay Amount"
                    className="form-control shadow-none"
                    value={this.state.twitterStayAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="twitterConnectAmount"
                    placeholder="Social Connect Amount"
                    className="form-control shadow-none"
                    value={this.state.twitterConnectAmount}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-3 pr-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="twitterPostAmount"
                    placeholder="Post Amount"
                    className="form-control shadow-none"
                    value={this.state.twitterPostAmount}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-4 pl-0">
                  <h5 className="pt-2">Daily Login</h5>
                </div>
                <div className="col-md-4 px-0">
                  <FormInput
                    icon="fa fa-calendar-o icon"
                    type="number"
                    name="dailyLoginConsecutiveDays"
                    placeholder="Daily Login Consecutive Days"
                    className="form-control shadow-none"
                    value={this.state.dailyLoginConsecutiveDays}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <FormInput
                    icon="fa fa-money icon"
                    type="number"
                    name="dailyLoginAmount"
                    placeholder="Daily Login Amount"
                    className="form-control shadow-none"
                    value={this.state.dailyLoginAmount}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <Button
                type="submit"
                value="Add Earning"
                className="btn btn-primary btn-block add-user-btn shadow-none mt-4"
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Earnings.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getEarnings: PropTypes.func.isRequired,
  updateEarnings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getEarnings, updateEarnings })(
  Earnings
);
