import React, { Component } from "react";
import "./styles.scss";
import { htmlToText } from 'html-to-text';
import {  getCMS } from "../../../actions/dashboardActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
       faqs: "",
    };
  }
  
  componentDidMount() {
    const token = localStorage.getItem("tizr_token");
    this.props.getCMS(token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms && nextProps.dashboard.cms.faq) {
        this.setState({
                faqs: nextProps.dashboard.cms.faq,
              });
    //     const convertedText = htmlToText(nextProps.dashboard.cms.faq, {
    //         wordwrap: false
    //       });
    //   this.setState({
    //     text: convertedText,
    //   });
    }
  }

  render() {
    const activeFaqs = this.props.dashboard.cms.faq && this.props.dashboard.cms.faq.filter(el => el.status === 'Active');
    return (
      <div className="container-fluid policy">
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            <h4>FAQ</h4>
            <hr />
          </div>
          {activeFaqs && activeFaqs.map((el) => (
              <>
            <div className="col-md-12 px-0">
          <h3>   <div dangerouslySetInnerHTML={ { __html: el.question} }></div></h3>
          </div>
          <div className="col-md-12 px-0">
            <p>   <div dangerouslySetInnerHTML={ { __html: el.answer} }></div></p>
          </div>
          </>
          ))}
        </div>
      </div>
    );
  }
}
Faq.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getCMS })(Faq);
