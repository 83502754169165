import isEmpty from "is-empty";

export const validateLogin = (id, password) => {
  if (isEmpty(id)) return "Please enter your email or phone number";

  if (isEmpty(password)) return "Please enter your password";
};

export const validateForgotPassword = (email) => {
  if (isEmpty(email)) return "Please enter your email";
};

export const validateResetPassword = (newPass, confirmPass) => {
  // if (isEmpty(oldPass)) return "Please enter your old password";

  if (isEmpty(newPass)) return "Please enter your new password";

  if (isEmpty(confirmPass)) return "Please confirm your new password";

  if (newPass !== confirmPass) return "Both password fields should be same";
};
