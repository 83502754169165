import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";

const VienSubMenuItem = ({ name, link }) => {
  return (
    <React.Fragment>
      <MenuItem>
        {name} <Link to={link} />
      </MenuItem>
    </React.Fragment>
  );
};

export default VienSubMenuItem;
