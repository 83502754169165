import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { Provider } from "react-redux";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/scss/bootstrap.scss";
import "./App.scss";
import { RouteWrapper } from "./components/startup/RouteWrapper.js";
import Login from "./components/auth/Login/Login";
import ForgotPassword from "./components/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword/ResetPassword";
import Admin from "./components/layouts/Admin";
import Dashboard from "./components/panel/Dashboard/Dashboard";
import Profile from "./components/panel/Profile/Profile";
import User from "./components/panel/Users/User";
import Earnings from "./components/panel/Earnings/Earnings";
import Notifications from "./components/panel/Notifications/Notifications";
import AdminNotifications from "./components/panel/AdminNotifications/AdminNotifications";
import Settings from "./components/panel/Settings/Settings";
import FAQ from "./components/panel/FAQ/addFAQ";
import Faq from "./components/panel/FAQ/Faq";
import ManageFAQ from "./components/panel/FAQ/ManageFAQ";
import PrivacyPolicy from "./components/panel/Policy/Policy";
import About from "./components/panel/CMS/About";
import Policy from "./components/panel/CMS/Policy";
import Terms from "./components/panel/CMS/Terms-and-condition";
import YoutubePolicy from "./components/panel/Youtube/YoutubePolicy";
import YoutubeTerms from "./components/panel/Youtube/Terms";
import TermsCondition  from "./components/panel/Terms/Terms";
import AboutUs from "./components/panel/About/About";
import Withdrawl from "./components/panel/Withdrawl/Withdrawl";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick
            transition={Slide}
          />
          <Switch>
            <Route exact path="/admin/login" component={Login} />
            <Route
              exact
              path="/admin/forgot-password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/admin/password/reset/:id/:token"
              component={ResetPassword}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-and-condition" component={TermsCondition} />
            <Route exact path="/about" component={AboutUs} />
            <Route exact path="/faq" component={Faq} />

            <RouteWrapper exact path="/" component={Dashboard} layout={Admin} />
            <RouteWrapper
              exact
              path="/admin/dashboard"
              component={Dashboard}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/profile"
              component={Profile}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/users"
              component={User}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/earnings"
              component={Earnings}
              layout={Admin}
            />
             <RouteWrapper
              exact
              path="/admin/notifications"
              component={Notifications}
              layout={Admin}
            />
              <RouteWrapper
              exact
              path="/admin/adminNotifications"
              component={AdminNotifications}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/withdrawls"
              component={Withdrawl}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/settings"
              component={Settings}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/faq/add"
              component={FAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/faq/manage"
              component={ManageFAQ}
              layout={Admin}
            />
            <RouteWrapper
              exact
              path="/admin/about"
              component={About}
              layout={Admin}
            />
              <RouteWrapper
               exact path="/admin/privacy-policy" 
               component={Policy} layout={Admin}/>
             <RouteWrapper
              exact
              path="/admin/terms-and-condition"
              component={Terms}
              layout={Admin}
            />

           <Route
                path="/youtube/privacy-policy" 
               component={YoutubePolicy}/>
             <Route
              exact
              path="/youtube/terms-and-condition"
              component={YoutubeTerms}
            />
          </Switch>
        </React.Fragment>
      </Router>
    </Provider>
  );
}

export default App;
