import {
  IS_LOADING,
  GET_PROFILE,
  GET_USERS,
  GET_USER,
  GET_EARNINGS,
  GET_CMS,
  GET_FAQ,
  ALL_NOTIFICATIONS,
  ALL_WITHDRAW_REQUESTS,
  GET_WITHDRAW,
  GET_CUSTOM_NOTIFICATION,
  ALL_ADMIN_NOTIFICATIONS,
} from "../actions/types";

const initialState = {
  loading: false,
  adminProfile: {},
  users: [],
  user: {},
  earnings: [],
  cms: [],
  faq: [],
  notifications: [],
  withdrawRequests: [],
  withdrawDetail : [],
  customNotifications: {},
  adminNotifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_PROFILE:
      return {
        ...state,
        adminProfile: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_EARNINGS:
      return {
        ...state,
        earnings: action.payload,
      };
    case GET_CMS:
      return {
        ...state,
        cms: action.payload,
      };
    case GET_FAQ:
      return {
        ...state,
        faq: action.payload,
      };
    
    case ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ALL_ADMIN_NOTIFICATIONS:
      return {
       ...state,
       adminNotifications: action.payload,
      };
    case ALL_WITHDRAW_REQUESTS:
      return {
        ...state,
        withdrawRequests: action.payload,
      };
    case GET_WITHDRAW:
    return {
        ...state,
        withdrawDetail: action.payload,
    };
    case GET_CUSTOM_NOTIFICATION:
      return {
        ...state,
        customNotifications: action.payload,
      };
    default:
      return state;
  }
}
