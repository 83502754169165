import isEmpty from "is-empty";

export const validateEarnings = (
  facebookConnectAmount,
  googleConnectAmount,
  googlePostAmount,
  twitterConnectAmount,
  twitterPostAmount,
  dailyLoginDays,
  dailyLoginAmt,
  facebookStayAmount,
  instagramStayAmount,
  twitterStayAmount,
  youtubeStayAmount
  
) => {
  if (isEmpty(facebookStayAmount)) return "Please enter facebook stay amount";
  if (isEmpty(instagramStayAmount)) return "Please enter instagram stay amount";
  if (isEmpty(twitterStayAmount)) return "Please enter twitter stay amount";
  if (isEmpty(youtubeStayAmount)) return "Please enter youtube stay amount";
  if (isEmpty(facebookConnectAmount)) return "Please enter facebook connect amount";

  

  if (isEmpty(googleConnectAmount)) return "Please enter google connect amount";
  if (isEmpty(googlePostAmount)) return "Please enter google post amount";

  if (isEmpty(twitterConnectAmount)) return "Please enter twitter connect amount";
  if (isEmpty(twitterPostAmount)) return "Please enter twitter post amount";

  if (isEmpty(dailyLoginDays)) return "Please enter daily login days";
  if (isEmpty(dailyLoginAmt)) return "Please enter daily login amount";
};
