import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";

import Pagination from "../../common/Pagination/Pagination";
import Search from "../../common/Search/Search";
import Button from "../../common/Button/Button";
import Radio from "../../common/Radio/Radio";
import AddUserModal from "../../common/Modal/AddUser";
import ViewUserModal from "../../common/Modal/ViewUser";
import EditUserModal from "../../common/Modal/EditUser";
import DeleteUserModal from "../../common/Modal/DeleteModal";
import { validateAddUser } from "../../../validations/add-user";
import {
  getAllUsers,
  getUser,
  searchUsers,
  createUser,
  suspendUserAccount,
  deleteUserAccount,
  activateUserAccount,
  updateUser,
  // sendNotificationToUser,
  sendNotificationToMultipleUser
} from "../../../actions/dashboardActions";
import "./styles.scss";
import { useAccordionToggle } from "react-bootstrap";

class ManageUser extends Component {
  constructor() {
    super();

    this.state = {
      id: "",
      showEditUserModal: false,
      showAddUserModal: false,
      showViewUserModal: false,
      showDeleteUserModal: false,
      name: "",
      username: "",
      email: "",
      phoneNumber: "",
      status: "",
      activeBtn: false,
      suspendBtn: false,
      query: "",
      pageLimit: 10,
      currentPage: 1,
      userType: "App",
      socialType: "",
      facebookBtn: true,
      googleBtn: false,
      twitterBtn: false,
      notificationType: '',
      selectAll: false,
      users: [],
    };
  }

  componentDidMount() {
    let { currentPage, pageLimit, userType } = this.state;

    const token = localStorage.getItem("tizr_token");
   this.props.getAllUsers(currentPage, pageLimit, userType, "", token);
    
  }

  componentWillReceiveProps(nextProps) {
   
 //   console.log("nextProps", nextProps.dashboard)
    
    if (nextProps.dashboard.user) {
      let {
        name,
        username,
        email,
        phoneNumber,
        isUserBlocked,
      } = nextProps.dashboard.user;
      
      this.setState({
        name,
        username,
        email,
        phoneNumber,
        status: isUserBlocked ? "Suspended" : "Active",
        activeBtn: isUserBlocked ? false : true,
        suspendBtn: isUserBlocked ? true : false,
      });
    }
  }

  addUserModalClicked = () => {
    this.setState({ showAddUserModal: true });
  };

  handleAddUserModalClose = () => {
    this.setState({ showAddUserModal: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = (query) => {
    this.setState({ query });
    const token = localStorage.getItem("tizr_token");
    this.props.searchUsers(query, token);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });

    let { pageLimit, userType, socialType } = this.state;

    const token = localStorage.getItem("tizr_token");
    this.props.getAllUsers(
      page,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };

  handleAddUserSubmit = (e) => {
    e.preventDefault();

    const {
      name,
      email,
      phoneNumber,
      currentPage,
      pageLimit,
      userType,
      socialType,
    } = this.state;

    const result = validateAddUser(name, email, phoneNumber);
    if (result) return toast.error(result);

    const user = {
      name,
      email,
      phoneNumber,
    };

    const token = localStorage.getItem("tizr_token");
    this.props.createUser(user, token);

    this.setState({
      showAddUserModal: false,
      name: "",
      email: "",
      phoneNumber: "",
    });

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };

  suspendUser = (id) => {
    const { currentPage, pageLimit, userType, socialType } = this.state;
    const token = localStorage.getItem("tizr_token");

    this.props.suspendUserAccount(id, token);

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };

  // deleteUser = (id) => {
  //   const { currentPage, pageLimit, userType, socialType } = this.state;
  //   const token = localStorage.getItem("tizr_token");

  //   this.props.deleteUserAccount(id, token);

  //   this.props.getAllUsers(
  //     currentPage,
  //     pageLimit,
  //     userType,
  //     userType === "Social" ? socialType : "",
  //     token
  //   );
  // };
  deleteUserModalClicked = (id) => {
    this.setState({ showDeleteUserModal: true, id });

    const token = localStorage.getItem("tizr_token");
    this.props.getUser(id, token);
  };
 handleYesBtnClick = () => {
   console.log("handle yes button click--", this.state.id)
   this.setState({showDeleteUserModal: false, id: ''})
  const { currentPage, pageLimit, userType, socialType } = this.state;
  const token = localStorage.getItem("tizr_token");

  this.props.deleteUserAccount(this.state.id, token);

  this.props.getAllUsers(
    currentPage,
    pageLimit,
    userType,
    userType === "Social" ? socialType : "",
    token
  );
  };
  handleNoBtnClick = () => {
    this.setState({ showDeleteUserModal: false});
  };
  handleDeleteModalClose = () => {
    this.setState({showDeleteUserModal: false, id: ''});
  };


  activateUser = (id) => {
    const { currentPage, pageLimit, userType, socialType } = this.state;
    const token = localStorage.getItem("tizr_token");

    this.props.activateUserAccount(id, token);

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };

  handleUserTypeChange = (e) => {
    this.setState({ userType: e.target.value });
    let { currentPage, pageLimit, socialType } = this.state;
    const token = localStorage.getItem("tizr_token");

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      e.target.value,
      e.target.value === "Social" ? socialType : "",
      token
    );
  };

  handleRadioChange = (type) => {
    let { currentPage, pageLimit, userType } = this.state;

    const token = localStorage.getItem("tizr_token");

    if (type === "Facebook") {
      this.setState({
        socialType: type,
        facebookBtn: true,
        googleBtn: false,
        twitterBtn: false,
      });
    }

    if (type === "Google") {
      this.setState({
        socialType: type,
        facebookBtn: false,
        googleBtn: true,
        twitterBtn: false,
      });
    }

    if (type === "Twitter") {
      this.setState({
        socialType: type,
        facebookBtn: false,
        googleBtn: false,
        twitterBtn: true,
      });
    }

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      userType,
      userType === "Social" ? type : "",
      token
    );
  };

  editUserModalClicked = (id) => {
    this.setState({ showEditUserModal: true, id });

    const token = localStorage.getItem("tizr_token");
    this.props.getUser(id, token);
  };

  handleEditUserModalClose = () => {
    this.setState({ showEditUserModal: false });
  };

  handleStatusChange = (status) => {
    if (status === "Active") {
      this.setState({
        status,
        activeBtn: true,
        suspendBtn: false,
      });
    } else {
      this.setState({
        status,
        activeBtn: false,
        suspendBtn: true,
      });
    }
  };

  handleEditUserSubmit = (e) => {
    e.preventDefault();

    let {
      id,
      email,
      name,
      username,
      phoneNumber,
      status,
      currentPage,
      pageLimit,
      userType,
      socialType,
    } = this.state;

    let result = validateAddUser(name, email, phoneNumber, username);
    if (result) return toast.error(result);

    const user = {
      id,
      email,
      name,
      username,
      phoneNumber,
      status,
    };

    const token = localStorage.getItem("tizr_token");

    this.props.updateUser(user, token);

    this.setState({
      showEditUserModal: false,
      name: "",
      email: "",
      phoneNumber: "",
      username: "",
      status: "",
      activeBtn: false,
      suspendBtn: false,
    });

    this.props.getAllUsers(
      currentPage,
      pageLimit,
      userType,
      userType === "Social" ? socialType : "",
      token
    );
  };

  viewUserModalClicked = (id) => {
    this.setState({ showViewUserModal: true, id });

    const token = localStorage.getItem("tizr_token");
    this.props.getUser(id, token);
  };
  // handleNotification = (type, userId) => {
  //   const token = localStorage.getItem("tizr_token");
  //   this.props.sendNotificationToUser(type, userId, token);
  // };

  handleViewUserModalClose = () => {
    this.setState({ showViewUserModal: false });
  };
  handleSendNotification = () => {
    const arrayofid = [];
    // Try to understand this code simple stuff
    const filterSelectedIds = this.state.users.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });
    this.setState({ notificationType: 1 });
    const token = localStorage.getItem("tizr_token");
    this.props.sendNotificationToMultipleUser(1, arrayofid, token);

    // this.props.getAllUsers(
    //   currentPage,
    //   pageLimit,
    //   e.target.value,
    //   token
    // );
  };
   handleCheck = (e, el) => {
    this.props.dashboard.users.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    const filter = this.props.dashboard.users.filter((item) => item.checked);
    if (filter.length === this.props.dashboard.users.length) {
      this.setState({selectAll: true});
    } else {
      this.setState({selectAll: false});
    }
    this.setState({users: this.props.dashboard.users});
  };

   handleSelectAll = () => {
     const array = [];
    this.setState({selectAll: !this.state.selectAll});
    this.props.dashboard.users.forEach((el) => {
      if (this.state.selectAll) {
        this.props.dashboard.users.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        this.setState({users: array});
      } else {
        this.props.dashboard.users.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="manage-user-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
        {  console.log("usersssssss", this.props.dashboard.users)}
          <h5>Manage Users</h5>
          <hr />
          <div className="manage-user">
            <div className="row mx-0">
              <div className="col-md-7 pl-0">
                <Button
                  type="button"
                  className="btn btn-primary add-new-user-btn shadow-none"
                  value="Add User"
                  handleClick={this.addUserModalClicked}
                />
              </div>
              <div className="col-md-5 pr-0">
                <Search
                  icon="fa fa-search icon"
                  size="15px"
                  placeholder="Search User"
                  styles={{
                    marginBottom: "20px",
                    fontSize: "15px",
                    paddingLeft: "35px",
                  }}
                  search={this.state.query}
                  handleSearch={this.handleSearch}
                />
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-md-4 px-0"></div>
              <div className="col-md-5 px-0 social">
                {this.state.userType === "Social" && (
                  <>
                    <Radio
                      name="social-type"
                      text="Facebook"
                      checked={this.state.facebookBtn}
                      handleChange={this.handleRadioChange}
                    />
                    <Radio
                      name="social-type"
                      text="Google"
                      checked={this.state.googleBtn}
                      handleChange={this.handleRadioChange}
                    />
                    <Radio
                      name="social-type"
                      text="Twitter"
                      checked={this.state.twitterBtn}
                      handleChange={this.handleRadioChange}
                    />
                  </>
                )}
              </div>
              <div className="col-md-3 px-0 form-group">
                <select
                  name="userType"
                  value={this.state.userType}
                  onChange={this.handleUserTypeChange}
                  className="form-control shadow-none"
                >
                  <option value="All">All</option>
                  <option value="App">Registered with App</option>
                  <option value="Social">Registered with Social Media</option>
                </select>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Referral Earning</th>
                  <th>Referred By</th>
                  <th>Status</th>
                  <th>Created On</th>
                  <th>Action</th>
                  <th> 
                  <input type="checkbox"
                    checked={this.state.selectAll}
                    onChange={this.handleSelectAll}
                  />       
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.dashboard.users &&
                  this.props.dashboard.users.length > 0 &&
                  this.props.dashboard.users.map((u, index) => (
                    <tr key={u._id}>
                      <td>{index + 1}</td>
                      <td>{u.name}</td>
                      <td>{u.username}</td>
                      <td>{u.email}</td>
                      <td>${parseFloat(u.referralEarning)}</td>
                      {/* <td> <Link
                          to="#"
                          onClick={() => this.handleNotification(1, u._id)}
                        >
                          <i class="fa fa-bell-o" aria-hidden="true"></i>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => this.handleNotification(2, u._id)}
                        >
                          <i class="fa fa-bell" aria-hidden="true"></i>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => this.handleNotification(3, u._id)}
                        >
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </Link>
                        </td> */}
                      <td>{u.referredBy ? u.referredBy.username : "-"}</td>
                      <td>
                        <span
                          className={u.isUserBlocked ? "suspended" : "active"}
                        >
                          {u.isUserBlocked ? "suspended" : "active"}
                        </span>
                      </td>
                      <td>{moment(u.created_at).format("MMM DD, YYYY")}</td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => this.viewUserModalClicked(u._id)}
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => this.editUserModalClicked(u._id)}
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                        {u.isUserBlocked ? (
                          <Link to="#" onClick={() => this.activateUser(u._id)}>
                            <i className="fa fa-unlock"></i>
                          </Link>
                        ) : (
                          <Link to="#" onClick={() => this.suspendUser(u._id)}>
                            <i className="fa fa-ban"></i>
                          </Link>
                        )}
                        <Link to="#" onClick={() => this.deleteUserModalClicked(u._id)}>
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </Link>
                      </td>
                      <td>
                        <input type="checkbox"  checked={u.checked}
                          value={u._id}
                          onClick={(e) => this.handleCheck(e.target.value, u)} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row mx-0">
              <div className="col-md-4 px-0"></div>
              <div className="col-md-5 px-0 social">
               
              </div>
              <div className="col-md-3 px-0 form-group">
              <Button
                  type="button"
                  className="btn btn-primary add-new-user-btn shadow-none"
                  value="Send Notification"
                  handleClick={this.handleSendNotification}
                />
                {/* <select
                  name="userType"
                  value={this.state.notificationType}
                  onChange={this.handleNotificationChange}

                  className="form-control shadow-none"
                >
                   <option>Select Notification</option>
                   <option value="1">Send Notification</option>
                  <option value="2">Notification Type 2</option>
                  <option value="3">Notification Type 3</option>
                  <option value="4">Notification Type 4</option>
                  <option value="5">Notification Type 5</option>
                  <option value="6">Notification Type 6</option>
                  <option value="7">Notification Type 7</option>
                  <option value="8">Notification Type 8</option>
                  <option value="9">Notification Type 9</option>
                  <option value="10">Notification Type 10</option>

                </select> */}
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
              <div className="col-md-6 pl-0">
                
                {console.log("pagination===>", this.state.query)}
                {!this.state.query && (
                  <Pagination
                    count={this.props.dashboard.users.length}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <AddUserModal
          showAddUserModal={this.state.showAddUserModal}
          name={this.state.name}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          handleAddUserModalClose={this.handleAddUserModalClose}
          handleAddUserSubmit={this.handleAddUserSubmit}
          handleChange={this.handleChange}
        />

        <EditUserModal
          showEditUserModal={this.state.showEditUserModal}
          name={this.state.name}
          username={this.state.username}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          activeBtn={this.state.activeBtn}
          suspendBtn={this.state.suspendBtn}
          handleEditUserModalClose={this.handleEditUserModalClose}
          handleEditUserSubmit={this.handleEditUserSubmit}
          handleChange={this.handleChange}
          handleStatusChange={this.handleStatusChange}
        />

        <ViewUserModal
          showViewUserModal={this.state.showViewUserModal}
          handleViewUserModalClose={this.handleViewUserModalClose}
          details={this.props.dashboard.user}
        />
        <DeleteUserModal
         showDeleteUserModal={this.state.showDeleteUserModal}
         handleDeleteModalClose={this.handleDeleteModalClose}
         handleYesBtnClick={this.handleYesBtnClick}
         handleNoBtnClick={this.handleNoBtnClick}
        />
      </React.Fragment>
    );
  }
}

ManageUser.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  suspendUserAccount: PropTypes.func.isRequired,
  activateUserAccount: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  sendNotificationToMultipleUser: PropTypes.func.isRequired,
  // sendNotificationToUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getAllUsers,
  getUser,
  searchUsers,
  createUser,
  suspendUserAccount,
  activateUserAccount,
  updateUser,
  deleteUserAccount,
  // sendNotificationToUser,
  sendNotificationToMultipleUser,
})(ManageUser);
