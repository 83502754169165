import React, { Component } from "react";

import Navbar from "../common/Navbar/Navbar";
import Sidebar from "../common/Sidebar/Sidebar";

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("tizr_token")) {
      this.props.history.push("/admin/login");
    }
  }

  handleToggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { collapsed } = this.state;

    return (
      <React.Fragment>
        <Navbar handleToggle={this.handleToggle} collapsed={collapsed} />
        <Sidebar collapsed={collapsed} handleToggle={this.handleToggle} />
        <div className="row mx-0">
          <div className="col-md-12 px-0">
            {this.props.children(this.state.collapsed)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Admin;
