import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    getAllAdminNotifications,
} from "../../../actions/dashboardActions";
import Pagination from "../../common/Pagination/Pagination";
import moment from "moment";
import "./styles.scss";

class AdminNotifications extends Component {
  constructor() {
    super();
    this.state = {
      query: "",
      pageLimit: 10,
      currentPage: 1,
    };
  }

  componentDidMount() {
    let { currentPage, pageLimit } = this.state;
    const token = localStorage.getItem("tizr_token");
    this.props.getAllAdminNotifications(currentPage, pageLimit, token);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    let { pageLimit } = this.state;
    const token = localStorage.getItem("tizr_token");
    this.props.getAllAdminNotifications(page, pageLimit, token);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="manage-user-withdrawls-wrapper"
          style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <h5>Manage Notifications</h5>
          <hr />
          <div className="manage-user-withdrawls">
            <div className="row mx-0">
              <div className="col-md-7 pl-0"></div>
              <div className="col-md-3 px-0 form-group">
             
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Email</th>
                  <th>Notification title</th>
                  <th>Notification Body</th>
                  <th>Send At</th>
                </tr>
              </thead>
              <tbody>
                {this.props.dashboard.adminNotifications &&
                  this.props.dashboard.adminNotifications.length > 0 &&
                  this.props.dashboard.adminNotifications.map((u, index) => (
                    <tr key={u._id}>
                      <td>{index + 1}</td>
                      <td>{u.userId && u.userId.email || '-'}</td>
                      <td>{u.notificationTitle}</td>
                      <td>{u.notificationBody}</td>
                      <td>{moment(u.notificationSendAt).format("MMM DD, YYYY")}</td>
                      {/* <td>
                        <span
                          className={u.isUserBlocked ? "suspended" : "active"}
                        >
                          {u.isUserBlocked ? "suspended" : "active"}
                        </span>
                      </td> */}
                      {/* <td>{moment(u.created_at).format("MMM DD, YYYY")}</td> */}
                      
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="row mx-0">
              <div className="col-md-6 pr-0"></div>
              <div className="col-md-6 pl-0">
                {!this.state.query && (
                  <Pagination
                    count={this.props.dashboard.adminNotifications.length}
                    pageLimit={this.state.pageLimit}
                    currentPage={this.state.currentPage}
                    handlePageClick={this.handlePageClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

AdminNotifications.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getAllAdminNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {getAllAdminNotifications })(AdminNotifications);
